import React from "react";

export const Main = () => {
  return (
    <div className="Main-container">
      <iframe
        title="main"
        className="Main"
        src="https://kkad.creatorlink.net/"
      ></iframe>
    </div>
  );
};

export default Main;
